import { React, useEffect, useState } from "react";
import axios from 'axios';
import Guiadisplay from "./Guiadisplay";
import Despliegacv from "./Despliegacv";


export default function Navbar() {
    const [cvguia, setCvguia] = useState('')
    const [especialidades, setEspecialidades] = useState([]);
    const [guias, setGuias] = useState([])
    const [ok, setOk] = useState(false)
    const getGuias = async () => {
        await axios.get('https://calendario.caminantes.cl/guias').then((response => {
            setGuias(response.data.data);
        }))
    }
    const getEspecialidades = async () => {
        await axios.get('https://calendario.caminantes.cl/especialidades').then((response => {
            setEspecialidades(response.data.data);
        }))
    }
    useEffect(() => {
        getEspecialidades()
        getGuias()
    }, [])
    return (
        <div id="container" className=" h-fit w-full andres">
            <div id="lineas-de-trabajo" className=' text-white texto-sombra font-semibold pt-2 text-center'>
                <div className=" text-amber-300 pb-6 text-4xl text-center font-extrabold">
                    {'CaminAntes'}
                </div>
                <div id="textos-lineas" className="flex flex-col mx-auto gap-6 lg:text-3xl text-2xl">
                    <div className="">
                        {'Centro de Terapias'}
                    </div>
                    <div className="">
                        {'Escuela Caminar Libre'}
                    </div>
                    <div className="cursor-pointer pb-4" onClick={() => { window.open('https://www.universitea.cl/') }}>
                        {'Capacitación y Certificación en Autismo y Neurodivergencia'}
                    </div>
                </div>
            </div>
            <div id="mapa" className="lg:text-2xl pt-2 w-fit mx-auto text-slate-100 
                        cursor-pointer font-bold rounded-lg lg:pt-4"
                onClick={() => {
                    window.open('https://www.google.com/maps/contrib/100704363978180777235/photos/@-33.519091,-70.5689633,17z/data=!3m1!4b1!4m3!8m2!3m1!1e1?entry=ttu&g_ep=EgoyMDI0MTAwOS4wIKXMDSoASAFQAw%3D%3D')
                }}>
                {'📍 Alicahue 7290, La Florida, Santiago'}
            </div>
            <div id="logo" className="pt-2 w-56 lg:w-72 mx-auto "
                onDoubleClick={() => { window.open('https://www.caminantes.cl/centro') }}>
                <img id="logo" alt="logo" src="./reforma4.png">
                </img>
                <div id="Whatsapp" className="pt-4 pb-2">
                    <div className="mx-auto">
                        <a target="_blank"
                            href="https://wa.me/56975114334?text=Hola Dolores, mi nombre es: " rel="noreferrer">
                            <img className="lg:h-12 h-10  mx-auto border-2 rounded-lg" src="./Whatsapp.png" alt="wap" ></img>
                        </a>
                    </div>
                </div>
            </div>
            <div id="especialidades-equipo" className="">
                <div id="especialidades" className="pt-2 pb-3 mx-auto texto-sombra">
                    <div className=" text-amber-300 pb-3 text-4xl text-center font-extrabold ">
                        {'Especialidades Terapias'}
                    </div>
                    <div id="lista-especialidades">
                        {especialidades && especialidades.map((esp, index) => {
                            return (
                                <div key={index} value={esp.SERVICIO}
                                    className="p-1 lg:pt-2 font-semibold text-3xl text-white text-center">
                                    {esp.SERVICIO}
                                </div>)
                        })
                        }
                    </div>
                </div>
                <div id="equipo" className=" mx-auto ">
                    <div className=" mx-auto text-amber-300 text-4xl text-center font-extrabold texto-sombra">
                        {'Nuestro Equipo'}
                    </div>
                    <div className="pt-6 flex flex-row gap-2 flex-wrap w-full">
                        {guias && guias
                            .map((g, index) => {
                                return (
                                    <div className="mx-auto flex" key={index}>
                                        <div hidden={ok}
                                            onClick={() => {
                                                if (g.CVGUIA.length > 0) {
                                                    setOk(!ok);
                                                    setCvguia({ cvguia: g.CVGUIA, codguia: g.CODGUIA, nomguia: g.NOMGUIA })
                                                }
                                            }}>
                                            <Guiadisplay guia={g} />
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                    <div id="cv" hidden={!ok} className="" onClick={() => { setOk(!ok) }}>
                        <Despliegacv texto={cvguia.cvguia} codguia={cvguia.codguia} nomguia={cvguia.nomguia} />
                    </div>
                </div>
            </div>
        </div>
    )
}

