
export default function Despliegacv(params) {
  var texto = params.texto

  if (texto !== undefined && texto.length > 0) {
    texto = texto.trim();
    // texto = texto.replace(/\.(\s)/g, '.\n');
    texto = texto.replace(/ {2}/g, '')
    return (
      <div className="px-2 pt-2 rounded-lg  bg-violet-800 ">
        <div className="flex flex-row">
          <img className='flex rounded-lg h-40 '
            src={'https://www.caminantes.cl/centro/images/guia/' + params.codguia + ".jpg"}
            alt={params.codguia}>
          </img>
          <div className="flex font-bold text-white text-xl mx-auto text-center my-auto w-1/2">
            {'Formación y Experiencia de ' + params.nomguia}
          </div>
          <div className="py-0">
            <div className="cursor-pointer  font-bold w-12 py-3.5 h-12 rounded-full 
              bg-amber-400 text-violet-700 text-center text-sm">
              {'Volver'}
            </div>
          </div>
        </div>
        <div className="pt-2">
          <textarea className="w-full h-auto px-2 text-xl  rounded-lg bg-violet-800 text-white" readOnly={true}
            rows={Math.trunc(texto.length / 25)} id='cv' value={texto}>
          </textarea>
        </div>

      </div>
    )
  }
  else { return ('') }

}
