import React from 'react'

export default function Guiadisplay(params) {
    return (<>
        <div id="guia" className=" bg-violet-500 border-violet-100 border-2 rounded-lg lg:w-56 w-44 h-full px-1">
            <div class="flex h-16 justify-center items-center">
                <div class="text-center">
                    <h1 class="text-2xl text-white">{params.guia.SERVICIO}</h1>
                </div>
            </div>
            <img className=' mx-auto rounded-lg w-40 pt-1 pb-1 h-40'
                src={'https://www.caminantes.cl/centro/images/guia/' + params.guia.CODGUIA + ".jpg"}
                alt={params.guia.CODGUIA}>
            </img>
            <div className='pt-1 font-semibold text-xl text-white text-center'>{params.guia.NOMGUIA}</div>
            <div class="flex h-16 justify-center items-center">
                <div class="text-center">
                    <div class="text-2xl text-white">{params.guia.PROFESION}</div>
                </div>
            </div>
        </div>{params.guia.CVGUIA!==''?
        <div className=" relative cursor-pointer left-2 bottom-7 font-bold w-5 h-5  rounded-full bg-amber-400 text-violet-700 text-center text-sm">
             {'Cv'} 
        </div>
        : ''}
    </>
    )
}
